import * as React from 'react'

const Header = () => {
    return (<header className='px-3'>
        <nav className="navbar navbar-light">
            <div className="container">
                <span className="navbar-brand mb-0">minima</span>
            </div>
        </nav>
    </header>)
}

export default Header