import * as React from "react"
import CKHowToProductOptin from '../CKHowToProductOptin'
import HeroImg from '../../images/hero.png'
import DeliverablesImg from '../../images/whatYouGet.png'

const HomePage = () => (
    <main>
        <section id="the-why" className="px-3 overflow-hidden">
            <div className="container py-5">
                <div className="row flex-column-reverse flex-md-row">
                    <div className="col-12 col-md-6">
                        <h1 className="mt-5">Launch in the AppStore in 60 days or less</h1>
                        <p className="hero my-5">Mobile apps shouldn’t take longer than vaccines to develop</p>
                        <CallToActionButton />
                    </div>
                    <div className="col-12 col-md-6 overflow-visible position-md-relative">
                        <img className="w-100 d-sm-block d-md-none" src={HeroImg} title='this could be us' alt="people enjoying a good app" />
                        <img className="d-none d-md-block h-100 position-md-absolute" src={HeroImg} title='this could be us' alt="people enjoying a good app" style={{ top: 0, left: 0 }} />
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-secondary py-5 px-3" id="the-how">
            <div className="container py-3" style={{ maxWidth: '520px' }}>
                <h2 className="my-5">Go from idea to an actual money-making product. It’s as easy as 1, 2, 3...</h2>
                <ProcessCard
                    position='first'
                    step={1}
                    subtitle='← all you'
                    text='The vision is the ultimate reason for creating your product; it describes the positive change it should bring to your business and to the world.'
                    title='Vision'
                />
                <ProcessCard
                    position='middle'
                    step={2}
                    subtitle="← together"
                    text="The what, how and in which order needs to be developed in order to fulfil your vision."
                    title='Planning'
                />
                <ProcessCard
                    position='last'
                    step={3}
                    subtitle="← all us"
                    text="The magical process of getting machines to do what we want them to do."
                    title='Development'
                />
            </div>
        </section>
        <section className="py-5 px-3" id='the-what'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 overflow-show position-relative">
                        <img src={DeliverablesImg} alt="people talking illustration" title="client and provider speaking the same language" className="d-none d-md-block position-absolute" style={{ top: '15%', right: '15%' }} />
                        <img src={DeliverablesImg} alt="people talking illustration" title="client and provider speaking the same language" className="d-md-none w-100 my-5" />
                    </div>
                    <div className="col-12 col-md-6">
                        <h2 className="my-5">Get everything you need to manage a technical product with ease</h2>
                        <h3 className="mt-md-5 mt-5 mb-4">Human readable documentation</h3>
                        <p>Documentation that reads in plain English. It reduces misunderstandings and saves countless hours of development time.</p>
                        {/* <a
                            className="btn btn-link text-muted px-0"
                            href="https://cyan-snowboard-10f.notion.site/mofodoro-documentation-22c964f90571407aa15e73a6b1ae1762"
                            target={'_blank'}
                        >
                            see sample
                        </a> */}
                        <h3 className="mt-md-5 mt-5 mb-4">Intuitive source code</h3>
                        <p>Good software is easy to read. It allows features to be added easily and new developers to pick it up quick. Following solid design principles you’ll get code that anyone can look over and understand.</p>
                        {/* <a
                            className="btn btn-link text-muted px-0"
                            href="https://cyan-snowboard-10f.notion.site/mofodoro-documentation-22c964f90571407aa15e73a6b1ae1762"
                            target={'_blank'}
                        >
                            see sample
                        </a> */}
                        <h3 className="mt-md-5 mt-5 mb-4">Predictable release schedule</h3>
                        <p>You get a new working version of your product every 2-4 weeks. Here are the release schedule of two companies. Which one do you think has better user rating?</p>
                        <CallToActionButton />
                    </div>
                </div>
            </div>
        </section>
        <section id='fallback' className="bg-secondary px-3">
            <div className='container'>
                <CKHowToProductOptin />
            </div>
        </section>
    </main>
)

const CallToActionButton = () => (
    <a
        href="https://ysvxtuybtjl.typeform.com/to/OdyTMreS"
        className="btn btn-primary btn-lg d-block d-md-inline-block px-5 my-5"
        target={'_blank'}
    >
        Get started <span>➔</span>
    </a>
)

const ProcessCard = (props: {
    position: 'first' | 'middle' | 'last',
    text: string,
    title: string,
    step: number,
    subtitle: string,
}) => {
    return <div className="my-5 py-2 d-flex">
        <div className="d-flex flex-shrink flex-column justify-content-center ps-2 pe-3">
            <div className="text-white bg-dark d-flex flex-column justify-content-center" style={{ height: '36px', width: '36px', borderRadius: '36px' }}>
                <p className="text-center mb-0"><strong>{props.step}</strong></p>
            </div>
        </div>
        <div>
            <h3 className="mb-1">{props.title} <span className="text-muted">{props.subtitle}</span></h3>
            <p className="mb-0">{props.text}</p>
        </div>
    </div>
}

export default HomePage