import React from 'react'
import BottomImg from '../images/pageEndCharacter.png'

const CKHowToProductOptin = () => (
    <div className='row py-md-5'>
        <div className='col-12 col-md-6 my-md-5 py-md-3'>
            <form action="https://app.convertkit.com/forms/4805815/subscriptions"
                className="seva-form formkit-form" method="post" data-sv-form="4805815" data-uid="7bed5acbcd" data-format="inline" data-version="5"
                data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;You're in. Check your email shortly for your welcome email! 🎉&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:false,&quot;url&quot;:&quot;https://minimacode.com/&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;hide&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}" min-width="400 500 600 700 800">
                <div data-style="minimal">
                    <h2 className='my-5'>Get the recipe for free</h2>
                    <p className='my-5'>Have you ever tried to cook something you saw on TV? Turns out you still need a step-by-step process to follow. It's the same with building apps...</p>
                    <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
                    <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields d-flex input-group-lg mt-5">
                        <label className="visually-hidden" htmlFor="emailInput">email</label>
                        <input className="form-control bg-white" id='emailInput' name="email_address" aria-label="Email Address" placeholder="name@company.com" required={true} type="email" />
                        <button data-element="submit" className="btn btn-primary ms-3">
                            <div className="formkit-spinner"><div></div><div></div><div></div></div><span>➔</span>
                        </button>
                    </div>
                    {/* <div className="formkit-guarantee text-center my-3" data-element="guarantee"><small className='text-muted' style={{ lineHeight: '0.3rem' }}>We won't send you spam and you can easily unsubscribe anytime.</small></div> */}
                </div>
            </form>
        </div>
        <div className='col-12 col-md-6 d-flex flex-column justify-content-center px-5 py-md-5 my-md-5'>
            <img src={BottomImg} alt='guy sitting illustration' title='kiss the cook' className='w-100 mt-5 mt-md-auto mb-n5 mb-md-auto' />
        </div>

        <script src="https://f.convertkit.com/ckjs/ck.5.js" />
    </div>)

export default CKHowToProductOptin

/**
 * copy
 * v1: Sign up for the mini-course on launching a product. Learn the common steps successful founders take to turn their ideas into hugely profitable products.
 * v2: Have you ever tried to cook something you saw on TV? Turns out you still need a step-by-step process to follow. It's the same with building apps. The goal of this mini-course is to give 
 */