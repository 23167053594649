import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/base.css'
import HomePage from "../components/Pages/HomePage"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Seo from "../components/Seo"

export default function IndexPage() {
  return (<>
    <Seo canonicalUrl="https://minimacode.com/" />
    <Header />
    <HomePage />
    <Footer />
  </>
  )
}